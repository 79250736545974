html,
body,
.carousel,
.carousel-inner,
.carousel-item {
  width: 100%;
  height: 100%;
}
p {
  margin: 0px;
}

.carousel {
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-fade .carousel-item {
  display: block;
  position: absolute;
  opacity: 0;
  transition: opacity .75s ease-in-out;
}
.carousel-fade .carousel-item.active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 10%;
  bottom: 1.25rem;
  left: 10%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 455px) {
  .carousel-control-next-icon {
    width: 1rem;
    height: 1rem;
  }
  .carousel-control-prev-icon {
    width: 1rem;
    height: 1rem;
  }
}

/* .player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
} */

/* .player-wrapper {
  position: relative;
  padding-top: 56.25%; // 720 / 1280 = 0.5625 
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
} */
